<template>
  <div :class="'action-log-item '+getPriorityByValue(action.action_priority_id)">
    <h3>
      <a @click="showAction(action)"
         :loading="'.action'+action.id"
      >
        {{ $tc('models.action', 1) | capitalize }} {{ action.id }}
      </a>
      <div class="buttons right">
        <a v-if="action.geo_object_id || action.map_object_id"
          @click="zoomToAction()" class="btn btn-icon module-color-background"
        >
          <font-awesome-icon icon="map-marker-alt" />
        </a>
        <ActionButton v-if="$can('show', 'action')"
                      @click="showAction(action)"
                      :action="action"
        />
      </div>
    </h3>
    <ul>
      <li>
        <span class="title">{{ $t('model.type') | capitalize }}: </span>
        <span class="content" v-if="action.dynamicActionType.data.name">
          {{ action.dynamicActionType.data.name }}
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li v-if="action.map_object_id">
        <span class="title">{{ $tc('models.object', 1) | capitalize }}: </span>
        <span class="content"
              v-if="action.mapObject && action.mapObject.data.name
               && action.mapObject.data.mapObjectType
                && action.mapObject.data.mapObjectType.data.name"
        >
          {{ action.mapObject.data.mapObjectType.data.name }}
          {{action.mapObject.data | mapObjectLabel}}
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $t('model.city_district') | capitalize }}: </span>
        <span class="content" v-if="action.geoObject.data.properties.city_district">
          {{action.geoObject.data.properties.city_district}}
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $tc('model.created_by', 1) | capitalize }}: </span>
        <span class="content" v-if="action.user">
          {{ action.user.data.name }}</span>
        <span class="content" v-else>?</span>
      </li>
      <li>
        <span class="title">{{ $t('model.created_at') | capitalize }}: </span>
        <span class="content">
          {{ action.created_at | dateTime }}
          <div v-if="action.device" style="float: right; font-size: 16px;">
            <DeviceIcon :device="action.device" />
          </div>
        </span>
      </li>
      <li>
        <span class="title">{{ $t('model.updated_at') | capitalize }}: </span>
        <span class="content">
          {{ action.updated_at | dateTime }}
        </span>
      </li>
      <li>
        <span class="title">{{ $t('model.status') | capitalize }}: </span>
        <span class="content" v-if="action.actionStatus.data.name">
          {{ action.actionStatus.data.name }}
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li v-if="action.date_interval">
        <span class="title">{{ $t('model.interval') | capitalize }}: </span>
        <span class="content">
          {{ $t('interval.'+dateIntervals[action.date_interval]) | capitalize }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import DateInterval from '@/enums/DateInterval';

import EventBus from '@/events/event-bus';

import ActionButton from '@/components/ActionButton.vue';
import DeviceIcon from './DeviceIcon.vue';

export default {
  name: 'ActionLogItem',
  components: {
    ActionButton,
    DeviceIcon,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateIntervals: DateInterval,
    };
  },
  methods: {
    showAction(action) {
      if (!this.$can('show', 'action')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.no_access_show', { model: this.$tc('models.action', 1) }),
        });
        return;
      }
      this.$emit('showAction', action.id);
    },
    zoomToAction() {
      const hasGeo = (this.action.geo_object_id && this.action.geoObject.data !== null);
      const hasMapObject = (this.action.map_object_id !== null);
      console.log('zoomToAction', hasGeo, hasMapObject, this.action.mapObject);
      let lat = null;
      let lon = null;
      if (hasGeo) {
        [lon, lat] = this.action.geoObject.data.geometry.coordinates;
      } else if (hasMapObject) {
        [lon, lat] = this.action.mapObject.data.geoObject.data.geometry.coordinates;
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.not_found', { model: this.$tc('models.geoobject', 1) }),
        });
        return;
      }
      if (hasMapObject) {
        if (this.action.mapObject.data.parent_id !== null) {
          EventBus.$emit('Map.addChild', this.action.mapObject.data, false);
        }
      }

      EventBus.$emit('Map.zoomTo', lat, lon);
    },
    objHasProp(obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop);
    },
    getPriorityByValue(actionPriorityId) {
      if (actionPriorityId === 1) {
        return 'low';
      } if (actionPriorityId === 2) {
        return 'medium';
      }
      return 'high';
    },
  },
};
</script>

<style scoped>
  a {
    cursor: pointer;
    font-size: 1.0em;
    margin: 0;
    padding: 0;
    color: #44a7dc;
    text-decoration: underline;
    outline: 0;
    transition: all 0.3s ease;
  }
  a.btn.btn-icon.zoomToAction {
    font-size: 0.8em;
  }

  .action-log-item {
    border-bottom: 1px solid #d9dadb;
    border-left: 4px solid white;
    padding: 0 20px;
  }

  .action-log-item.low {
    border-left-color: #44a7dc;
  }

  .action-log-item.medium {
    border-left-color: #ff9900;
  }

  .action-log-item.high {
    border-left-color: #ee335f;
  }

  .action-log-item h3 {
    margin: 0;
    padding: 12px 0;
  }

  .right {
    float: right;
  }

  ul {
    overflow: auto;
    zoom: 1;
    font-size: .85em;
    margin: 0 0 15px 0;
    padding: 0;
  }

  ul li {
    line-height: 1.7em;
    position: relative;
    clear: both;
    list-style: none;
  }

  ul li span {
    display: block;
    float: left;
    width: 40%;
  }

  ul li span.title {
    width: 40%;
  }

  ul li span.content {
    display: block;
    float: left;
    width: 55%;
  }
</style>
