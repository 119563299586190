<template>
  <ActionModal
    :title="$t('actionlog.create_action')"
    :action_type_name="dynamicActionType.name"
    :visible="visible"
    @closeModal="closeModal"
    :loading="loading || mapObjectLoading || dynamicActionTypeLoading || mapObjectTypeLoading"
  >
    <template v-slot:left-content>
      <ActionModalMapObjectInformation
        v-if="mapObject"
        :map_object="mapObject"
      />
      <ActionModalNetworkInformation v-else />

      <div
        v-if="action.attribute && action.attribute.data && action.attribute.data.length > 0"
        class="action-attribute-wrapper"
      >
        <h2>{{ $t('actionlog.action_details') }}</h2>
        <div v-if="isCreatesObject">
          <span class="title">Type:</span>
          <div class="field content">
            <MultiSelect
              :options="mapObjectTypeConstraints"
              v-model="selectedMapObjectTypeConstraint"
              track-by="id"
              label="name"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              :preselect-first="true"
              placeholder=""
              selectLabel=""
              selectedLabel=""
              deselectLabel=""
            />
          </div>
        </div>

        <div v-for="attribute in filteredAttributes" :key="attribute.id">
          <span v-if="attribute.required" class="title">
              <span class="required">*</span>
              {{ attribute.name }}:
          </span>
          <span v-else class="title">{{ attribute.name }}:</span>
          <div class="field content">
            <AttributeInput :disabled="false" :attribute="attribute" />
          </div>
        </div>
      </div>

      <div class="action-image-types"
           v-if="actionImageTypes && $can('index', 'actionimage')"
      >
        <div
          v-for="actionImageType in actionImageTypes"
          :key="actionImageType.id"
          class="images-field"
        >
          <h2 class="action-type-header">{{ actionImageType.name }}</h2>
          <div class="field">
            <ImagePanel
              :action_image_type_id="actionImageType.id"
              :action_images="actionImages"
              :selectable="copyImages"
              @addImage="onImageAdd"
              @deleteImage="onImageDelete"
            />
          </div>
        </div>
      </div>

      <div>
        <h2>{{ $t('actionlog.extra_options') }}</h2>
        <div>
          <input @change="changeRecurring" type="checkbox" :checked="createRecurring"/>
          {{ $t('actionlog.recurring_actions_checkbox') }}
          <div v-if="createRecurring" class="field">
            <label for="date_interval">{{ $t('model.interval') |capitalize }}</label>
            <select id="date_interval" name="date_interval" v-model="dateInterval">
              <option
                v-for="(title, key) in dateIntervals"
                :key="key"
                :value="key"
              >
                {{ $t('interval.'+title) | capitalize }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right-content>
      {{ $tc('models.status', 1) | capitalize }}:
      <div class="field">
        <ActionStatusSelect
          v-if="action.action_status_id"
          v-model="action.action_status_id"
          :action_statuses="actionStatuses"
        />
      </div>
      <div class="field">
        <input type="checkbox" v-model="goToProcess"/>
        {{ $t('actionlog.process_action_checkbox') }}
      </div>
      {{ $tc('models.priority', 1) | capitalize }}:
      <ActionPriorityButtons
        v-if="action.action_priority_id"
        v-model="action.action_priority_id"
      />
      <hr/>
      <div v-if="$can('create', 'comment')">
        <h2>
          {{ $t('actionlog.add_comment') | capitalize }}
        </h2>
      </div>
      <div v-if="$can('create', 'comment')" class="field">
            <textarea
              v-model="comment"
              name="comment"
              :placeholder="$t('actionlog.add_comment_placeholder')"
            >
            </textarea>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-content">
        <a @click="closeModal" class="btn grey">
          {{ $t('actionlog.cancel') | capitalize }}
        </a>
        <a
          :title="!$can('create', 'action') ? $t('error.no_access_button') : ''"
          @click="createAction"
          :class="['btn', 'module-color-background', {'disabled': !$can('create', 'action')}]"
        >
          {{ $t('actionlog.create') | capitalize }}
        </a>
      </div>
    </template>
  </ActionModal>
</template>

<script>
import MultiSelect from 'vue-multiselect';

import { mapActions } from 'vuex';
// import EventBus from '@/events/event-bus';

import ActionApi from '@/api/action';
import ActionStatusApi from '@/api/action_status';
import DynamicActionTypeApi from '@/api/dynamic_action_type';
import MapObjectTypeApi from '@/api/map_object_type';
import RecurringActionApi from '@/api/recurring_action';

import DateInterval from '@/enums/DateInterval';

import AttributeInput from '@/components/AttributeInput.vue';
import ImagePanel from '@/components/ImagePanel.vue';
import ActionModal from './ActionModal.vue';
import ActionPriorityButtons from './ActionPriorityButtons.vue';
import ActionStatusSelect from './ActionStatusSelect.vue';
import ActionModalMapObjectInformation from './ActionModalMapObjectInformation.vue';
import ActionModalNetworkInformation from './ActionModalNetworkInformation.vue';

export default {
  name: 'CreateActionModal',
  components: {
    ActionModalNetworkInformation,
    MultiSelect,
    AttributeInput,
    ImagePanel,
    ActionModal,
    ActionPriorityButtons,
    ActionStatusSelect,
    ActionModalMapObjectInformation,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dynamic_action_type_id: {
      type: Number,
      required: true,
    },
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
    latlng: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      action: {
        action_status_id: null,
        dynamic_action_type_id: this.dynamic_action_type_id,
        action_priority_id: 1,
        device: 'web',
      },
      dynamicActionType: {},
      actionImageTypes: [],
      actionStatuses: [],
      actionPriorityId: 1,
      loading: false,
      dynamicActionTypeLoading: false,
      mapObjectLoading: false,
      showObjectAttributeBox: false,
      createRecurring: false,
      copyImages: false,
      goToProcess: false,
      actionImages: [],
      comment: '',
      dateInterval: 'P1D',
      dateIntervals: DateInterval,
      isChangeRequest: false,
      isCreatesObject: false,
      isDeletesObject: false,
      mapObjectTypeConstraints: [],
      mapObjectTypeLoading: false,
      selectedMapObjectTypeConstraint: null,
      requiredcheck: true,
    };
  },
  computed: {
    images() {
      if (!this.action.actionImage || !this.action.actionImage.data) {
        return [];
      }
      return this.action.actionImage.data;
    },
    selectedStatus() {
      if (this.action_statuses.length === 0) {
        return {};
      }
      return this.action_statuses.filter(
        status => status.id === parseInt(this.action.action_status_id, 10),
      )[0];
    },
    filteredAttributes() {
      let attributes = {};
      if (this.action && this.action.attribute && this.action.attribute.data) {
        attributes = this.action.attribute.data;

        if (this.isChangeRequest && !this.isCreatesObject && !this.isDeletesObject
          && this.mapObject && this.mapObject.attribute && this.mapObject.attribute.data) {
          const mapObjectAttributes = this.mapObject.attribute.data.map(attribute => attribute.id);
          attributes = attributes.filter(
            attribute => (mapObjectAttributes.indexOf(attribute.id) > -1),
          );
        }
      }

      return attributes;
    },
  },
  methods: {
    ...mapActions({
      addAction: 'settings/addAction',
    }),
    closeModal() {
      this.$emit('closeModal');
    },
    updateActionLog() {
      this.$emit('updateActionLog');
    },
    goToProcessAction(actionId) {
      this.$emit('goToProcessAction', actionId);
    },
    onImageAdd(actionImageTypeId, file) {
      if (!this.$can('create', 'actionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionimage', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.actionimage', 0) }),
        });
        return;
      }
      this.actionImages[actionImageTypeId].push(file);
    },
    onImageDelete(actionImageTypeId, index) {
      if (!this.$can('delete', 'actionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionimage', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.actionimage', 1) }),
        });
        return;
      }
      this.actionImages[actionImageTypeId].splice(index, 1);
      this.$set(this.actionImages, actionImageTypeId, this.actionImages[actionImageTypeId]);
    },
    changeRecurring() {
      this.createRecurring = !this.createRecurring;
    },
    changeCopyImages() {
      this.copyImages = !this.copyImages;
    },
    createAction() {
      if (!this.$can('create', 'action')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.action', 0) }),
        });
        return;
      }
      this.loading = true;
      const data = {
        data: Object.assign({}, this.action),
      };
      console.log('createAction() latlng', this.latlng);
      if (this.dynamicActionType.own_geo && this.latlng && this.latlng.lat && this.latlng.lng) {
        this.$delete(data.data, 'geo_object_id');
        data.data.geoObject = {
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.latlng.lng, this.latlng.lat],
            },
            properties: {
              country_code: 528,
            },
          },
        };
      }
      if (this.isCreatesObject) {
        data.data.map_object_type_id = this.selectedMapObjectTypeConstraint.id;
      }
      const attributesdata = data.data.attribute.data;
      attributesdata.forEach((item) => {
        if (item.required && !item.value) {
          this.requiredcheck = false;
        }
      });
      console.log('createAction() data', data.data);
      if (this.requiredcheck) {
        ActionApi.createAction(data, 'geoObject')
          .then((response) => {
            const actionId = response.data.id;

            if (this.comment.length > 0) {
              this.createComment(actionId);
            }
            this.handleImages(actionId);
            if (this.createRecurring) {
              this.createRecurringAction(actionId);
            }

            this.addAction({ action: response.data });
            // setTimeout(() => {
            //   EventBus.$emit('Map.redraw');
            // }, 500);

            this.finishUp();

            if (this.goToProcess) {
              this.$emit('goToProcessAction', actionId);
            }
          })
          .catch((error) => {
            console.log('action create error', error, error.response);
            this.$notify({
              type: 'error',
              title: this.$t('error.create_failed', { model: this.$tc('models.action', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('error.update_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.required_fields_not_completed'),
        });
        this.loading = false;
        this.requiredcheck = true;
      }
    },
    finishUp() {
      this.loading = false;
      this.$emit('closeModal');
      this.$emit('updateActionLog');
      // EventBus.$emit('Map.redraw');
    },
    // eslint-disable
    async handleImages(actionId = this.action_id) {
      const allImages = this.actionImages.flat();

      // eslint-disable-next-line no-restricted-syntax
      for (const image of allImages) {
        if (image.file) {
          const formData = new FormData();
          formData.append('image', image.file);
          formData.append('action_image_type_id', image.action_image_type_id);
          // eslint-disable-next-line no-await-in-loop
          await ActionApi.createImageForAction(actionId, formData)
            .then((response) => {
              image.id = response.data.id;
            });
        }
      }

      if (this.copyImages) {
        await this.copyImagesToMapObject(allImages);
      }
    },
    createComment(actionId) {
      if (this.comment.length > 0) {
        const commentData = {
          data: {
            comment: this.comment,
          },
        };
        return ActionApi.createCommentForAction(actionId, commentData)
          .then(() => {
            this.comment = '';
            return true;
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.create_failed', { model: this.$tc('models.comment', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
      return true;
    },
    createRecurringAction() {
      const data = {
        data: {
          map_object_type_id: this.action.map_object_type_id,
          action_status_id: this.action.action_status_id,
          map_object_id: this.action.map_object_id,
          geo_object_id: this.action.geo_object_id,
          dynamic_action_type_id: this.action.dynamic_action_type_id,
          action_priority_id: this.action.action_priority_id,
          date_interval: this.dateInterval,
          is_relative: false,
        },
      };
      return RecurringActionApi.createRecurringAction(data).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.create_failed', { model: this.$tc('models.recurringaction', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    getMapObject() {
      this.mapObjectLoading = true;
      this.action.map_object_id = this.mapObject.id;
      this.action.map_object_type_id = this.mapObject.map_object_type_id;
      this.action.geo_object_id = this.mapObject.geo_object_id;

      this.getDynamicActionType();

      this.mapObjectLoading = false;
    },
    getNetwork() {
      this.mapObjectLoading = true;
      this.getDynamicActionType();
      this.mapObjectLoading = false;
    },
    getDynamicActionType() {
      this.dynamicActionTypeLoading = true;
      DynamicActionTypeApi.getDynamicActionTypeById(this.dynamic_action_type_id, 'attribute,attribute.attributeLookupValue,actionImageType,dynamicActionTypeCategory')
        .then(
          (response) => {
            this.dynamicActionType = response.data;
            // eslint-disable-next-line max-len
            this.isChangeRequest = this.dynamicActionType.dynamicActionTypeCategory.data.is_change_request;
            this.isCreatesObject = this.dynamicActionType.creates_object;
            this.isDeletesObject = this.dynamicActionType.deletes_object;

            if (this.isCreatesObject) {
              this.getMapObjectTypeConstraints();
            }
            this.$set(this.action, 'dynamicActionType', { data: this.dynamicActionType });
            if (this.dynamicActionType.attribute && this.dynamicActionType.attribute.data) {
              this.action.attribute = this.dynamicActionType.attribute;
            }
            if (this.dynamicActionType.actionImageType
              && this.dynamicActionType.actionImageType.data) {
              this.actionImageTypes = this.dynamicActionType.actionImageType.data;
            }
            this.handleActionImages();
            this.dynamicActionTypeLoading = false;
          },
        ).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.dynamicactiontype', 1) }),
            data: error.response,
          });
          this.dynamicActionTypeLoading = false;
        });
    },
    getMapObjectTypeConstraints() {
      this.mapObjectTypeLoading = true;
      const mapObjectTypeId = this.mapObject.map_object_type_id;
      MapObjectTypeApi.getConstraintsByMapObjectTypeId(mapObjectTypeId).then((response) => {
        this.mapObjectTypeConstraints = response.data;
        [this.selectedMapObjectTypeConstraint] = this.mapObjectTypeConstraints;
        this.mapObjectTypeLoading = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjecttype', 0) }),
          data: error.response,
        });
        this.mapObjectTypeLoading = false;
      });
    },
    getActionStatuses() {
      ActionStatusApi.getActionStatus().then((response) => {
        this.actionStatuses = response.data
          .filter(actionStatus => !actionStatus.is_final)
          .sort((a, b) => (a.weight > b.weight ? 1 : -1));
        this.action.action_status_id = this.actionStatuses[0].id;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionstatus', 0) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    handleActionImages() {
      if (this.action.dynamicActionType && this.action.dynamicActionType.data) {
        if (this.action.dynamicActionType.data.actionImageType
          && this.action.dynamicActionType.data.actionImageType.data) {
          this.action.dynamicActionType.data.actionImageType.data.forEach((imageType) => {
            this.$set(
              this.actionImages,
              imageType.id,
              this.images.filter(
                image => image.action_image_type_id === imageType.id,
              ),
            );
          });
        }
      }
    },
  },
  mounted() {
    if (this.mapObject) {
      this.getMapObject();
    } else {
      this.getNetwork();
    }
    this.getActionStatuses();
  },
};
</script>
<style scoped>
  hr {
    border: 0;
    border-bottom: 1px solid #d9dadb;
  }

  .footer-content {
    float: right;
  }

  .action-image-types {
    display: flex;
  }

  div.images-field {
    position: relative;
    display: inline-block;
    width: 50%;
  }
  .action-attribute-wrapper .title {
    display: inline-flex;
    float: initial;
    width: 35%;
  }

  .action-attribute-wrapper .field.content {
    display: inline-flex;
    float: initial;
    overflow: initial;
    width: 65%;
  }

  .action-attribute-wrapper {
    margin-top: 20px;
  }
  .action-attribute-wrapper .required {
    color: red;
  }

</style>
