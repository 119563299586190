<template>
  <div>
    <BreadcrumbSelection
      v-if="selection"
      :id="selection.id"
      :name="selection.name"
      :type="selection.mapObjectSelectionType.data.name"
      :showBackButton="true"
      :customBackAction="true"
      @backButtonPressed="goBack"
    />
    <CollapsePanel
      :title="$t('selections.selection_info')"
      :loading="loading || !selection"
      :showCount=false
      :showCollapseAction="!selectionLocked && ($can('update', 'mapobjectselection')
                            || $can('delete', 'mapobjectselection'))"
      :collapsed="true"
      icon="pen"
      @collapseAction="toggleShowCollapseMenu"
    >
      <template v-slot:customCollapseAction v-if="showCollapseMenu">
        <CollapseActionMenu>
          <CollapseActionMenuItem
            v-if="$can('update', 'mapobjectselection')"
            :title="$t('button.edit') | capitalize"
            icon="pen"
            @clickItem="toEditSelection"
          />
          <CollapseActionMenuItem
            v-if="$can('delete', 'mapobjectselection')"
            :title="$t('button.delete') | capitalize"
            icon="trash"
            @clickItem="deleteSelection"
          />
        </CollapseActionMenu>
      </template>
      <div class="selection-information-block" v-if="selection">
        <div v-if="selection.status === 'broken'" class="warning-broken-selection">
          <font-awesome-icon class="exclamation" icon="exclamation-circle"/>
          Let op: Deze selectie is kapot!
          <font-awesome-icon class="exclamation" icon="exclamation-circle"/>
          <br/>
          {{ findBrokenPart }}
        </div>
        <h3 class="block-header">{{ $t('selections.selection_data') }}</h3>
        <ul class="block-details">
          <li class="block-item" v-if="user.super_admin">
            <span class="block-item-title">{{ $t('model.id') | capitalize }}:</span>
            <span class="block-item-content">
                {{ selection.id }}
              </span>
          </li>
          <li class="block-item">
            <span class="block-item-title">{{ $t('model.name') | capitalize }}:</span>
            <span class="block-item-content">
                {{ selection.name }}
              </span>
          </li>
          <li class="block-item">
            <span class="block-item-title">{{ $t('model.description') | capitalize }}:</span>
            <span class="block-item-content">
              {{ selection.description }}
            </span>
          </li>
          <li class="block-item">
            <span class="block-item-title">{{ $t('model.owner') | capitalize }}:</span>
            <span class="block-item-content">
              {{ owner_name }}
            </span>
          </li>
        </ul>
        <div v-if="selection.attribute &&
             selection.attribute.data &&
             selection.attribute.data.length > 0"
        >
          <h3 class="block-header">{{ $t('selections.attribute_data') }}</h3>
          <ul class="block-details">
            <li v-for="attribute in selection.attribute.data"
                :key="attribute.id"
                class="block-item"
            >
              <span class="block-item-title">{{ attribute.name | capitalize }}:</span>
              <span class="block-item-content">
                <AttributeValue :attribute="attribute"/>
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="block-header">{{ $t('selections.location_data') }}</h3>
          <ul class="block-details">
            <li class="block-item">
              <span class="block-item-title">{{ $t('model.total_length') | capitalize }}:</span>
              <span class="block-item-content">
              {{ selectionLength }}
            </span>
            </li>
          </ul>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel
      v-if="$can('index', 'mapobjectselectionimage')"
      :title="$tc('models.image', 0)"
      :loading=loading
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="field">
        <GenericImagePanel
          :images="images"
          :show_only="false"
          model_type="mapobjectselectionimage"
          @addImage="onImageAdd"
          @deleteImage="onImageDelete"
        />
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MapObjectSelectionsApi from '@/api/map_object_selection';
import TeamApi from '@/api/team';
import UserApi from '@/api/user';

import CollapsePanel from '@/components/CollapsePanel.vue';
import GenericImagePanel from '@/components/GenericImagePanel.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import AttributeValue from '@/components/AttributeValue.vue';
import BreadcrumbSelection from '@/modules/selections/components/BreadcrumbSelection.vue';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';

export default {
  name: 'ShowSelection',
  components: {
    BreadcrumbSelection,
    AttributeValue,
    CollapseActionMenuItem,
    CollapseActionMenu,
    GenericImagePanel,
    CollapsePanel,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      keyValues: 'settings/getKeyValues',
      networkId: 'switcher/getNetworkId',
      tenantId: 'switcher/getTenantId',
      actionStatuses: 'settings/getActionStatuses',
      selection: 'selected/getSelection',
    }),
    mapObjectSelectionLock() {
      const lockItems = Object.values(this.keyValues)
        .filter(item => item.key.includes(`lock_mapobjectselections_${this.networkId}`));
      if (lockItems.length > 0) {
        return lockItems[0];
      }
      return null;
    },
    selectionLength() {
      if (!this.selection) {
        return null;
      }
      if (!this.selection.mapObject || !this.selection.mapObject.data) {
        return null;
      }
      if (this.selection.mapObject.data.length === 0) {
        return 0;
      }
      let length = parseFloat(0);
      this.selection.mapObject.data.forEach((mapObject) => {
        if (mapObject.geoObject.data.geometry.type === 'LineString'
          && mapObject.geoObject.data.properties
          && mapObject.geoObject.data.properties.length) {
          length += parseFloat(mapObject.geoObject.data.properties.length);
        }
      });

      if (length > 1100) {
        return `${(length / 1000).toFixed(2)} km`;
      }
      return `${length.toFixed(0)} m`;
    },
    findBrokenPart() {
      let brokenText = '';
      if (this.selection.mapObjectSelectionType.data.is_ordered) {
        let mapObjects = JSON.parse(JSON.stringify(this.selection.mapObject.data));
        const startObj = this.loopOverObjects(mapObjects);
        mapObjects = mapObjects.reverse();
        const endObj = this.loopOverObjects(mapObjects);
        if (startObj !== null && endObj !== null) {
          if (startObj.id === endObj.id) {
            const startName = (startObj.mapObjectLabel && startObj.mapObjectLabel.data.label)
              ? startObj.mapObjectLabel.data.label
              : startObj.name;
            const startType = this.$t(`geo_type.${startObj.geoObject.data.geometry.type.toLowerCase()}`);

            brokenText = this.$t('selections.one_point_broken', { startType, startName });
          } else {
            const startName = (startObj.mapObjectLabel && startObj.mapObjectLabel.data.label)
              ? startObj.mapObjectLabel.data.label
              : startObj.name;
            const startType = this.$t(`geo_type.${startObj.geoObject.data.geometry.type.toLowerCase()}`);

            const endName = (endObj.mapObjectLabel && endObj.mapObjectLabel.data.label)
              ? endObj.mapObjectLabel.data.label
              : endObj.name;
            const endType = this.$t(`geo_type.${endObj.geoObject.data.geometry.type.toLowerCase()}`);

            brokenText = this.$t('selections.two_points_broken', {
              startType, startName, endType, endName,
            });
          }
        }
      }
      return brokenText;
    },
  },
  data() {
    return {
      loading: false,
      owner_name: '-',
      showCollapseMenu: false,
      selectionLocked: false,
      images: [],
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
      resetSelection: 'selected/resetSelection',
    }),
    goBack() {
      const type = this.selection.map_object_selection_type_id;
      this.resetSelection();
      this.$router.push({ name: 'selection.list', params: { type } });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    toEditSelection() {
      this.$router.push({ name: 'selection.edit', params: { id: this.selection.id } });
    },
    deleteSelection() {
      if (!this.$can('delete', 'mapobjectselection')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.mapobjectselection', 1) }),
        });
        return;
      }
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_selection_confirm'))) {
        this.loading = true;
        MapObjectSelectionsApi.deleteMapObjectSelectionById(this.selection.id)
          .then(() => {
            this.goBack();
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.delete_failed', { model: this.$tc('models.mapobjectselection', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
    },
    onImageAdd(file) {
      if (!this.$can('create', 'mapobjectselectionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselectionimage', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.mapobjectselectionimage', 0) }),
        });
        return;
      }
      const formData = new FormData();
      formData.append('image', file.file);
      MapObjectSelectionsApi.createImageForMapObjectSelection(this.selection.id, formData)
        .then((response) => {
          // TODO v2 version of selections that returns image
          this.images.push(response.data);
        });
    },
    onImageDelete(index) {
      if (!this.$can('delete', 'mapobjectselectionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselectionimage', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.mapobjectselectionimage', 1) }),
        });
        return;
      }
      const image = this.images.splice(index, 1);
      MapObjectSelectionsApi.deleteImageForMapObjectSelection(this.selection.id, image[0].id)
        .then(() => {
        });
    },
    loopOverObjects(data) {
      let objectWithError = null;

      let previous = null;
      data.forEach((mapObject) => {
        if (objectWithError !== null) {
          return;
        }
        if (previous === null) {
          previous = mapObject;
          return;
        }
        let line = null;
        let point = null;
        if (mapObject.geoObject.data.geometry.type === 'LineString'
          && previous.geoObject.data.geometry.type === 'Point') {
          line = mapObject;
          point = previous;
        } else if (mapObject.geoObject.data.geometry.type === 'Point'
          && previous.geoObject.data.geometry.type === 'LineString') {
          line = previous;
          point = mapObject;
        } else {
          objectWithError = mapObject;
          return;
        }
        if (point.id !== line.geoObject.data.properties.start_point
          && point.id !== line.geoObject.data.properties.end_point) {
          objectWithError = mapObject;
          return;
        }
        previous = mapObject;
      });
      return objectWithError;
    },
    checkSelectionLocked() {
      this.loading = true;
      if (this.selection.created_by !== null
        && this.selection.created_by !== undefined
        && this.mapObjectSelectionLock !== null) {
        if (this.user.id === this.selection.created_by) {
          this.owner_name = this.user.name;
          if (this.mapObjectSelectionLock.value === 'team') {
            this.owner_name += ` (${this.user.team.data.map(item => item.name).join(', ')})`;
          }
        } else if (this.mapObjectSelectionLock.value === '' || this.mapObjectSelectionLock === 'user') {
          if (!this.$can('overrule', 'locking-mapobjectselection')) {
            this.selectionLocked = true;
          }
          UserApi.getUser(this.selection.created_by)
            .then((r2) => {
              const user = r2.data;
              this.owner_name = user.name;
            });
        } else if (this.mapObjectSelectionLock.value === 'team') {
          TeamApi.checkSameTeam(this.tenantId, this.user.id, this.selection.created_by)
            .then((sameTeam) => {
              if (!sameTeam && !this.$can('overrule', 'locking-mapobjectselection')) {
                this.selectionLocked = true;
              }
              UserApi.getUser(this.selection.created_by, 'team')
                .then((r2) => {
                  const user = r2.data;
                  this.owner_name = user.name;
                  if (this.mapObjectSelectionLock.value === 'team') {
                    this.owner_name += ` (${user.team.data.map(item => item.name).join(', ')})`;
                  }
                });
            });
        }
      }
      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      this.selectedId = parseInt(this.$route.params.id, 10);
      if (!this.selection || this.selection.id !== this.selectedId) {
        this.fetchSelection({ id: this.selectedId }).then((data) => {
          console.log('mounted selections', data);
          this.checkSelectionLocked();
          EventBus.$emit(
            'Map.changeMapMode',
            MapMode.selectionSelect,
          );
          EventBus.$emit('Map.zoomToMapObjects', data.mapObject.data);
        });
      } else {
        this.checkSelectionLocked();
        EventBus.$emit(
          'Map.changeMapMode',
          MapMode.selectionSelect,
        );
        EventBus.$emit('Map.zoomToMapObjects', this.selection.mapObject.data);
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': function (id) {
      if (id !== null && id !== undefined) {
        this.selectedId = parseInt(id, 10);
        if (!this.selection || this.selection.id !== this.selectedId) {
          this.fetchSelection({ id: this.selectedId }).then(() => {
            this.checkSelectionLocked();
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.selection-information-block {
  padding: 0 20px;
}

.selection-information-block .warning-broken-selection {
  color: rgba(238, 51, 95, 1);
  margin-bottom: 10px;
}

.selection-information-block .warning-broken-selection .exclamation {
  color: rgba(238, 51, 95, 1);
}

.selection-information-block ul.block-details {
  overflow: auto;
  zoom: 1;
  font-size: .85em;
  margin: 0 0 20px 0;
  padding: 0;
}

.selection-information-block ul.block-details li.block-item {
  line-height: 1.7em;
  position: relative;
  clear: both;
  list-style: none;
}

.selection-information-block ul.block-details li.block-item .block-item-title {
  display: block;
  float: left;
  width: 40%;
}

.selection-information-block ul.block-details li.block-item .block-item-content {
  display: block;
  float: left;
  width: 60%;
}

.selection-information-block h3.block-header {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  margin: 0 0 5px;
  padding: 0;
  color: #42464c;
}
</style>
