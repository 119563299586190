/* eslint-disable no-shadow,no-param-reassign,no-return-assign,no-sequences */
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import MapObjectApi from '@/api/map_object';
// eslint-disable-next-line import/no-cycle
import MapObjectSelectionApi from '@/api/map_object_selection';
// eslint-disable-next-line import/no-cycle
import NetworkApi from '@/api/network';
import EventBus from '@/events/event-bus';
import GeoType from '@/enums/GeoType';

const mapObjectIncludes = [
  'action',
  'mapObjectLabel',
  'startPoint',
  'startPoint.mapObjectLabel',
  'endPoint',
  'endPoint.mapObjectLabel',
  'children',
  'children.action',
  'children.mapObjectLabel',
  'mapObjectType',
  'mapObjectStatus',
  'attribute',
  'attribute.attributeLookupValue',
  'mapObjectSelection',
  'parents',
  'parents.mapObjectSelection',
  'parents.mapObjectType',
  'parent',
  'parent.mapObjectType',
  'createdBy',
  'createdBy.team',
];

const selectionIncludes = [
  'mapObject',
  'mapObject.mapObjectLabel',
  'mapObject.startPoint',
  'mapObject.endPoint',
  'mapObjectSelectionImage',
  'mapObjectSelectionType',
  'attribute',
  'attribute.attributeLookupValue',
];

const state = {
  updatedAt: null,
  mapObject: null,
  action: null,
  selection: null,
};

const actions = {
  fetchMapObject({ commit, state, dispatch }, { id, force = false }) {
    if (!force && state.mapObject && parseInt(state.mapObject.id, 10) === parseInt(id, 10)) {
      return null;
    }
    // commit('SET_MAP_OBJECT', null);
    console.log('selected fetchMapObject()', id);
    EventBus.$emit('Map.loading', true);
    return MapObjectApi.getMapObjectbyId(id, mapObjectIncludes.join(','))
      .then((response) => {
        console.log('ZZZ selected fetchMapObject', response.data);
        commit('SET_MAP_OBJECT', response.data);
        dispatch('settings/updateMapObject', { mapObject: response.data }, { root: true });
        EventBus.$emit('Map.loading', false);
        return response.data;
      })
      .catch(() => {
      });
  },
  fetchMapObjectByLatLng({ commit }, { networkId, lat, lng }) {
    console.log('store selected fetchMapObjectByLatLng()', networkId, lat, lng);
    EventBus.$emit('Map.loading', true);
    return NetworkApi.findNearestObject(networkId, lat, lng, mapObjectIncludes.join(','))
      .then((response) => {
        if (response && Object.values(response).length > 0) {
          commit('SET_MAP_OBJECT', response.data);
          EventBus.$emit('Map.loading', false);
          return response.data;
        }
        return null;
      });
  },
  // eslint-disable-next-line no-unused-vars
  findMapObjectByLatLng({ commit }, { networkId, lat, lng }) {
    console.log('store selected findMapObjectByLatLng()', networkId, lat, lng);
    EventBus.$emit('Map.loading', true);
    return NetworkApi.findNearestObject(networkId, lat, lng, mapObjectIncludes.join(','), GeoType.linestring)
      .then((response) => {
        if (response && Object.values(response).length > 0) {
          EventBus.$emit('Map.loading', false);
          return response.data;
        }
        return null;
      });
  },
  resetMapObject({ commit }) {
    console.log('store selected resetMapObject()');
    commit('SET_MAP_OBJECT', null);
  },
  fetchSelection({ commit, state }, { id, force = false }) {
    if (!force && state.selection && parseInt(state.selection.id, 10) === parseInt(id, 10)) {
      return null;
    }
    EventBus.$emit('Map.loading', true);
    return MapObjectSelectionApi.getMapObjectSelectionById(id, selectionIncludes.join(','))
      .then((response) => {
        commit('SET_SELECTION', response.data);
        EventBus.$emit('Map.loading', false);
        return response.data;
      })
      .catch(() => {
      });
  },
  resetSelection({ commit }) {
    commit('SET_SELECTION', null);
  },
};

const mutations = {
  SET_UPDATED_AT(state, updatedAt) {
    state.updatedAt = updatedAt;
  },
  SET_MAP_OBJECT(state, mapObject) {
    console.log('SELECTED SET_MAP_OBJECT', mapObject);
    Vue.set(state, 'mapObject', mapObject);
  },
  SET_ACTION(state, action) {
    Vue.set(state, 'action', action);
  },
  SET_SELECTION(state, selection) {
    Vue.set(state, 'selection', selection);
  },
};

const getters = {
  getUpdatedAt: state => state.updatedAt,
  getMapObject: state => state.mapObject,
  getMapObjectId: state => (state.mapObject ? state.mapObject.id : null),
  getAction: state => state.action,
  getActionId: state => (state.action ? state.action.id : null),
  getSelection: state => state.selection,
  getSelectionId: state => (state.selection ? state.selection.id : null),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
