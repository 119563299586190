<template>
  <div v-if="mapObject">
    <Loading :visible="loading || !mapObject" />
    <BreadcrumbMapObject v-if="mapObject"
                         :mapObject="mapObject"
                         @clickBack="goToView"
                         @clickParent="goToView"
    />
    <ObjectInformation v-if="$can('show', 'mapobject')"
                       :mapObject="mapObject"
    />
    <ObjectChildren v-if="$can('show', 'mapobject')"
                    :mapObject="mapObject"
    />
    <ObjectObstructions v-if="$can('show', 'mapobject')"
                        :mapObject="mapObject"
    />
    <ActionLog v-if="$can('index', 'action')"
               :mapObject="mapObject"
    />
    <ObjectComments v-show="$can('show', 'mapobject')"
                    :mapObject="mapObject"
    />
    <Images
      v-if="$can('show', 'mapobject')
          && $can('index', 'mapobjectimage')"
      :mapObject="mapObject"
    />
    <Administration
      v-if="$can('module', 'beheerplus')"
      :mapObject="mapObject"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BreadcrumbMapObject from '@/components/Layout/BreadcrumbMapObject.vue';

import ActionLog from './components/ActionLog.vue';
import Administration from './components/Administration.vue';
import Images from './components/Images.vue';
import ObjectChildren from './components/ObjectChildren.vue';
import ObjectComments from './components/ObjectComments.vue';
import ObjectInformation from './components/ObjectInformation.vue';
import ObjectObstructions from './components/ObjectObstructions.vue';
import Loading from '@/components/Loading.vue';

export default {
  name: 'route_management.show_object',
  components: {
    Loading,
    BreadcrumbMapObject,
    ActionLog,
    Administration,
    Images,
    ObjectChildren,
    ObjectComments,
    ObjectInformation,
    ObjectObstructions,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
    }),
  },
  data() {
    return {
      loading: false,
      showBackButton: false,
      selectedId: null,
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      resetMapObject: 'selected/resetMapObject',
      resetSelected: 'map/resetSelected',
      addSelected: 'map/addSelected',
    }),
    goToView(model, id) {
      if (model === 'network') {
        this.resetMapObject();
        this.resetSelected();
        this.$router.push({ name: 'route_management' });
      } else {
        this.$router.push({ name: 'route_management.show', params: { id } });
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      this.selectedId = parseInt(this.$route.params.id, 10);
      if (!this.mapObject || this.mapObject.id !== this.selectedId) {
        this.fetchMapObject({ id: this.selectedId });
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': function (id) {
      if (id !== null && id !== undefined) {
        this.loading = true;
        this.selectedId = parseInt(id, 10);
        if (!this.mapObject || this.mapObject.id !== this.selectedId) {
          console.log('ZZZ ShowObject watch fetchMapObject');
          this.fetchMapObject({ id: this.selectedId }).then(() => {
            this.loading = false;
          });
        }
      }
    },
    mapObject(value) {
      if (value) {
        this.resetSelected();
        if (value.geoObject.data.geometry.type === 'LineString') {
          if (value.startPoint && value.startPoint.data) {
            this.addSelected({ mapObject: value.startPoint.data });
          }
          if (value.endPoint && value.endPoint.data) {
            this.addSelected({ mapObject: value.endPoint.data });
          }
        }
        this.addSelected({ mapObject: value });
      }
    },
  },
};
</script>

<style scoped>
</style>
